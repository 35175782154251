import React from 'react';
import PropTypes from 'prop-types';
import {
  HashRouter,
  Route,
  Routes,
  Navigate
} from 'react-router-dom';
import Home from './components/Home';
import Methodology from './components/Methodology';
import Contact from './components/Contact';
import NotFound from './components/404';
import history from './history';
import App from './App';

const reload = () => window.location.reload();

function Router({ store }) {
  return (
    <HashRouter history={history}>
      <Routes>
        <Route
          path="/"
          element={<Navigate replace to="home" />}
        />
        <Route path="home" element={<Home />} />
        <Route path="/methodology" element={<Methodology />} />
        <Route path="/contact" element={<Contact />} />
        <Route
          path="/app"
          element={<App store={store} location={window.location} />}
        />
        <Route path="/sitemap.xml" onEnter={reload} />
        <Route path="/robots.txt" onEnter={reload} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </HashRouter>
  );
}

Router.propTypes = {
  store: PropTypes.object.isRequired
};

export default Router;
