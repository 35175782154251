import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import MaterialTable from 'material-table';
import Search from '@mui/icons-material/Search';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import Launch from '@mui/icons-material/Launch';
import { setReviewRefsOpen } from '../actions';
import { ui } from '../constants';

function ReviewReferences({
  windowSize, reviewRefsOpen, data, closeReviewRefs
}) {
  if (data === undefined) {
    return '';
  }

  const keys = Object.keys(data);
  const refs = keys.map((k) => ({
    author: data[k].author,
    cat: data[k].chapter_article_title,
    jbwt: data[k].journal_book_website_title,
    year: data[k].year,
    // eslint-disable-next-line jsx-a11y/control-has-associated-label
    url: (<a href={data[k].url} target="_blank" rel="noopener noreferrer"><Launch /></a>)
  }));

  return (
    <Dialog open={reviewRefsOpen} onClose={closeReviewRefs}>
      <div className="review-refs-container" style={{ width: windowSize.appWidth, marginTop: ui.header.height - 4, left: windowSize.appLeft }}>
        <MaterialTable
          components={{
            /* eslint-disable */
            Container: ({ children }) => (
              <div style={{ background: '#ffffff', width: windowSize.appWidth }}>
                {children}
              </div>
            )
            /* eslint-enable */
          }}
          icons={{
            Search,
            FirstPage,
            LastPage,
            NextPage: ChevronRight,
            PreviousPage: ChevronLeft
          }}
          columns={[
            { title: 'Author(s)', field: 'author' }, // cellStyle: { color: '#fff' } },
            { title: 'Title', field: 'cat' }, // cellStyle: { color: '#fff' } },
            { title: 'Journal', field: 'jbwt' }, // cellStyle: { color: '#fff' } },
            { title: 'Year', field: 'year' }, // cellStyle: { color: '#fff' } },
            {
              title: 'Source',
              field: 'url',
              // cellStyle: { a: { color: '#fff' } },
              filtering: false,
              sorting: false
            }
          ]}
          data={refs}
          title="References"
        />
      </div>
    </Dialog>
  );
}

ReviewReferences.propTypes = {
  windowSize: PropTypes.object.isRequired,
  reviewRefsOpen: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  closeReviewRefs: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  windowSize: state.windowSize,
  filters: state.filters,
  reviewRefsOpen: state.reviewRefsOpen,
  data: state.refsData.data
});

const mapDispatchToProps = (dispatch) => ({
  closeReviewRefs: () => {
    dispatch(setReviewRefsOpen(false));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReviewReferences);
