import React from 'react';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <Box display="flex" flexWrap="wrap" className="footer">
      <Box flexGrow={1}>
        &copy;
        {` ${new Date().getFullYear()} `}
        <a
          href="https://www.gatesfoundation.org/"
          target="_blank"
          rel="noreferrer noopener"
        >
          Bill & Melinda Gates Foundation.
        </a>
        &nbsp;All Rights Reserved.
        {/* {` v${process.env.REACT_APP_VERSION}`} */}
      </Box>
      <Box className="footer-text">
        <a
          href="https://www.gatesfoundation.org/Terms-of-Use"
          target="_blank"
          rel="noreferrer noopener"
        >
          Terms of Use
        </a>
      </Box>
      <Box className="footer-text">
        <a
          href="https://www.gatesfoundation.org/Privacy-and-Cookies-Notice"
          target="_blank"
          rel="noreferrer noopener"
        >
          Privacy & Cookies Notice
        </a>
      </Box>
      <Box className="footer-text">
        <Link to="/methodology">Methodology</Link>
      </Box>
      <Box className="footer-text">
        <Link to="/contact">Contact Us</Link>
      </Box>
    </Box>
  );
}

export default Footer;
