/* eslint-disable import/no-import-module-exports */
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import {
  legacy_createStore as createStore, compose, applyMiddleware
} from 'redux';
import { thunk } from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { throttle } from 'throttle-debounce';
import { ThemeProvider, createTheme } from '@mui/material';
import 'typeface-roboto';
import 'typeface-roboto-condensed';
import 'typeface-barlow';
import {
  fetchData, fetchRefsData, fetchNetworkData, windowResize
} from './actions';
import { ui } from './constants';
import './assets/index.css';
import './assets/ageSlider.css';
import './assets/networkGraph.css';
import './assets/icons/style.css';
import reducers from './reducers';
import * as serviceWorker from './serviceWorker';
import Router from './Router';

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

let store;

if (process.env.NODE_ENV !== 'production') {
  store = createStore(
    reducers,
    // { initial state... },
    composeEnhancer(applyMiddleware(thunk, createLogger()))
  );
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      store.replaceReducer(reducers);
    });
  }
} else {
  store = createStore(
    reducers,
    // { initial state... },
    composeEnhancer(applyMiddleware(thunk))
  );
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#4EB8C1'
    },
    secondary: {
      main: '#4EB8C1'
    }
  }
});

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);
root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <Router store={store} />
    </ThemeProvider>
  </Provider>
);

store.dispatch(fetchData('events.json'));
store.dispatch(fetchRefsData('refs.json'));
store.dispatch(fetchNetworkData('orfi.json'));

const resize = () => {
  store.dispatch(windowResize({
    height: window.innerHeight,
    width: window.innerWidth,
    appWidth: Math.min(ui.maxWidth, window.innerWidth),
    appLeft: (window.innerWidth - Math.min(ui.maxWidth, window.innerWidth)) / 2
  }));
};

// trigger resize at beginning
resize();

// listen for future resizes (throttled)
window.addEventListener('resize', throttle(300, () => {
  resize();
}));

// registerServiceWorker();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
