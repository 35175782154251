import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Header from './components/Header';
import Body from './components/Body';
import FilterVariable from './components/FilterVariable';
import AgeRange from './components/AgeRange';
import ReviewReferences from './components/ReviewReferences';
import LinkDialog from './components/LinkDialog';
import { setFilterOpen } from './actions';
import { setStateFromHash } from './utils/hash';

function App(props) {
  const { location, store } = props;
  const { hash } = location;

  useEffect(() => {
    if (hash !== '' && hash !== '#') {
      store.dispatch(setFilterOpen(false));
      setStateFromHash(store, hash);
    }
  }, [hash, store]);

  return (
    <div className="root">
      <Header />
      <Body />
      <FilterVariable />
      <AgeRange />
      <ReviewReferences />
      <LinkDialog />
    </div>
  );
}

App.propTypes = {
  location: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired
};

export default App;
